function template(strings, ...keys) {
    return (function(...values) {
        var dict = values[values.length - 1] || {}
        var result = [strings[0]]
        keys.forEach(function(key, i) {
            var value = Number.isInteger(key) ? values[key] : dict[key]
            result.push(value, strings[i + 1])
        })
        return result.join('')
    })
}

export var directory = template`
<tr class="file ">
    <td class="icon">
        <i class="${'iconClass'}"></i>
    </td>
    <td class="name">
        <a href="/${'path'}">${'pathDisplay'}</a>
    </td>
    <td class="age">
        <time class="js-relative-time" datetime="${'lastModifiedDateTime'}">${'lastModifiedDisplay'}</time>
    </td>
    <td class="filesize">
        <data class="js-bytesize" value="${'sizeBytes'}">${'sizeDisplay'}</data>
    </td>
    <td class="ios-tools"></td>
</tr>
`

export var iosTools = template`
<button class="button ios-only js-install-ipa" data-link="${'link'}">
    <i class="fab fa-apple fa-w-14 min-w1 w-auto"></i>
    Install
</button>
`

export var provisioningInfo = template`
<tr class="meta">
    <td colspan="5">
        <dl>
            <dt>Name</dt>
            <dd>${'bundleName'}</dd>
            <dt>Team Name</dt>
            <dd>${'bundleTeamName'}</dd>
            <dt>Profile</dt>
            <dd>${'bundleProvisioningExpiration'}</dd>
            <dt>Cert</dt>
            <dd>${'bundleDeveloperCertificateExpiration'}</dd>
            <dt>UUID</dt>
            <dd>${'bundleProvisioningUUID'}</dd>
        </dl>
    </td>
</tr>
`

export var file = template`
<tr class="file actual-file">
    <td class="icon">${'fileIcon'}</td>
    <td class="name">
      <a href="/${'path'}">${'name'}</a>
    </td>
    <td class="age">
      <time class="js-relative-time" datetime="${'lastModifiedDateTime'}">${'lastModifiedDisplay'}</time>
    </td>
    <td class="filesize">
      
      <data class="js-bytesize" value="${'sizeBytes'}">${'sizeDisplay'}</data>
      
    </td>
    <td class="ios-tools">${'iosTools'}</td>
</tr>
`

export var pinnedDirectory = template`
<div>
    <div class="name">
        <a href="/${'path'}">${'name'}</a>
    </div>
    <div class="path">/${'path'}</div>
    <div class="description">${'description'}</div>
</div>
`

export var console = template`
                 ,     ,
                (\\____/)
                 (_oo_)
                   (O)
                 __||__    \\)
              []/______\\[] /
              / \\______/ \\/
             /    /__\\
            (\\   /____\\ v${'version'}
  ____        _ _     _ ____        _   
 | __ ) _   _(_) | __| | __ )  ___ | |_ 
 |  _ \\| | | | | |/ _\` |  _ \\ / _ \\| __|
 | |_) | |_| | | | (_| | |_) | (_) | |_ 
 |____/ \\__,_|_|_|\\__,_|____/ \\___/ \\__|
`