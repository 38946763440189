// deps
import $ from 'jquery'
import _ from 'lodash'
import filesize from 'filesize'
import moment from 'moment'
import path from 'path'
import natsort from 'natsort'

// internal
import * as buildbot from './buildbot.api'
import * as templates from './templates'
import './handlers'

// styles
import './fontawesome-all.min.css'
import './style.scss'

// json
import fileIcons from "./file-icons.json"
import quotes from "./quotes"

// TODO: do this at build-time for performance reasons
// explode all icons into separate objects
let icons = []
_.forEach(fileIcons, icon => {
    _.forEach(icon.ext.split(' '), ext => {
        icons.push({ ext: ext, class: icon.class} )
    })
})

// settings
let title = "Intelity Builds"
let defaultIcon = _.find(icons, { ext: 'default' })
let folderIcon = _.find(icons, { ext: 'folder' })
let isApple = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g))
let urlPath = window.location.pathname.replace(/^\/+/, '')

// divs
let loginFormDiv = $('#login-form')
let pinnedDirectoriesDiv = $('.pinned-directories')
let pathInfoDiv = $('.path-info')
let filesDiv = $('.files')
let containerDiv = $('.directory.container')
let greetingDiv = $('.js-greeting')
let breadcrumbsDiv = $('.breadcrumbs')
let noBreadcrumbsDiv = $('.no-breadcrumbs')
let logoutButton = $('#logout')

init()

function init() {
    // console init
    console.log(templates.console({version:process.env.npm_package_version}))

    console.log(`title: ${title}`)
    console.log(`defaultIcon: ${defaultIcon}`)
    console.log(`isApple: ${isApple}`)
    console.log(`urlPath: ${urlPath}`)
    console.log(`authToken: ${localStorage.getItem('authToken')}`)

    // ensure all ajax calls contain bearer token
    buildbot.makeAjaxWrapper()

    // detect ios device
    if (isApple) containerDiv.addClass('ios')

    // by default, hide login-form because visiblity:hidden doesn't remove from DOM but display:none overrides display:flex
    // TODO: different approach to hiding/showing divs (not using visiblity css)
    loginFormDiv.hide()
    pathInfoDiv.hide()

    // TODO: show quote/loading animation while content loads

    // check if logged in or not
    if (localStorage.getItem('authToken')) {
        containerDiv.show()
    } else {
        logoutButton.hide()
        containerDiv.hide()
        // TODO: different approach to hiding/showing divs (not using visiblity css)
        loginFormDiv.css('visibility', 'visible')
        loginFormDiv.show()
        return
    }

    // display the page
    updateFileListing(urlPath)

    if (urlPath === "") {
        buildbot.getPinnedArtifacts()
            .then(pinnedJSON => {

                console.log(pinnedJSON)

                _.forEach(pinnedJSON, pin => {
                    pinnedDirectoriesDiv.append(
                        $(templates.pinnedDirectory({
                            path: pin.path,
                            name: pin.name,
                            description: pin.description
                        }))
                    )
                })
            })
    }
}

// TODO: handle missing trailing slash, currently if missing last segment isn't displayed
function updateBreadcrumbs(pathString) {

    if (pathString === "") {
        breadcrumbsDiv.hide()

        // random quote
        let randomQuote = _.sample(quotes)
        greetingDiv.html(`<span class="quote">${randomQuote.quote}</span> <span class="author">${randomQuote.author}</span>`)

        noBreadcrumbsDiv.show()
        document.title = title
    } else {
        document.title = `${pathString.replace(/\/$/, '')} • ${title}`
        noBreadcrumbsDiv.hide()

        // clear div
        breadcrumbsDiv.empty()

        // build path
        let pathItems = pathString.split('/')
        let breadcrumbItems = []
        let breadcrumbHref = '/'

        breadcrumbItems.push($(`<span class="root"><a href="${breadcrumbHref}">buildbot</a></span>`))

        // remove last empty item
        pathItems.length--

        pathItems.forEach(item => {
            breadcrumbHref += `${item}/`
            breadcrumbItems.push($('<span class="separator">/</span>'))
            breadcrumbItems.push($('<span>', {
                class: 'segment'
            }).append($('<a>', {
                href: breadcrumbHref,
                text: item
            })))
        })

        breadcrumbsDiv.append(breadcrumbItems)
        breadcrumbsDiv.show()
    }
}

function updateFileListing(pathString) {

    updateBreadcrumbs(pathString)
    pinnedDirectoriesDiv.toggle(pathString === "")

    buildbot.getArtifacts(pathString)
        .then(filesJSON => {

            $('.path-info .root-size .value').text(filesize(filesJSON.rootSize))
            $('.path-info .path-size .value').text(filesize(filesJSON.pathSize))

            // TODO: do this with css only
            $('.path-info .root-size').toggle(filesJSON.path !== null)

            filesDiv.empty()

            console.log(filesJSON)

            let items = []
            let sorter = natsort({insensitive: true})
            filesJSON.directories.sort((a, b) => {
                return sorter(a.path, b.path)
            })

            _.forEach(filesJSON.directories, dir => {
                let segments = dir.path.split('/')
                items.push(templates.directory({
                    path: dir.path,
                    pathDisplay: dir.name,
                    iconClass: folderIcon.class,
                    sizeBytes: dir.totalSize,
                    sizeDisplay: filesize(dir.totalSize),
                    lastModifiedDateTime: dir.lastModifiedDateTime,
                    lastModifiedDisplay: moment(dir.lastModifiedDateTime).fromNow(),
                }))
            })

            _.forEach(filesJSON.files, file => {

                // relative path
                let filePath = `${(file.path)}/${file.name}`

                // root dir, so use absolute path
                if (!file.path) {
                    filePath = `/${(window.location.host)}/${file.name}`
                }

                // ignore files that should be hidden
                if (_.find(icons, { ext: path.extname(file.name) || file.name, hide: true })) return

                let foundIcon = _.find(icons, { ext: path.extname(file.name) || file.name })
                let fileIcon = (foundIcon) ? `<i class="${foundIcon.class}"></i>` : `<i class="${defaultIcon.class}"></i>`

                items.push(
                    $(templates.file({
                            fileIcon: fileIcon,
                            path: filePath,
                            name: file.name,
                            sizeBytes: file.size,
                            sizeDisplay: filesize(file.size),
                            lastModifiedDateTime: file.lastModifiedDateTime,
                            lastModifiedDisplay: moment(file.lastModifiedDateTime).fromNow(),
                            iosTools: (file.iOSInstallUrl) ? templates.iosTools({path: `${filePath}`, link: file.iOSInstallUrl}) : ''
                        }))
                )

                if (file.iOSInstallUrl && file.metadata) {
                    items.push(
                        $(templates.provisioningInfo({
                            bundleName: file.metadata.bundleName,
                            bundleTeamName: file.metadata.bundleTeamName,
                            bundleProvisioningExpiration: moment(file.metadata.bundleProvisioningExpiration).fromNow(),
                            bundleDeveloperCertificateExpiration: moment(file.metadata.bundleDeveloperCertificateExpiration).fromNow(),
                            bundleProvisioningUUID: file.metadata.bundleProvisioningUUID
                        }))
                    )
                }
            })

            filesDiv.append(items)
            pathInfoDiv.show()
        })
        .catch(err => {
            console.error(err)

            // folder not found, let's try for a file
            // TODO: what to render here?
            if (err.status === 404) {
                buildbot.getDownload(pathString)
                    .then(res => {
                        window.location = res.downloadUrl
                    })
            }
        })
}