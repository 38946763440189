import $ from 'jquery'

export const API_URL = "https://api.builds.build.intelitydev.com"
export function getAuthToken(user, pwd) {
    var data = `username=${encodeURIComponent(user)}&password=${encodeURIComponent(pwd)}`
    //var formData = new formData(data)
    console.log(`Making auth request to server with data: ${data}`)
    return $.ajax({
        type: "POST",
        crossDomain: true,
        contentType: "application/x-www-form-urlencoded",
        url: `${API_URL}/token`,
        data: data,
        success: function (data) {
            // data.expiresIn is seconds. Expected to be 86,400 (seconds in a day)
            if (data.accessToken === undefined) {
                // if access token isn't defined....
            } else {
                console.log(`Token: ${data.accessToken}`)
                var currentSeconds = new Date() / 1000
                var tokenExpiration = currentSeconds + data.expiresIn  // Save token expiration as epoch seconds.
                localStorage.setItem("authToken", data.accessToken)
                localStorage.setItem("authExpiration", tokenExpiration)

                return
            }
        },
        error: function (data) {
            //toastr.error('The login information provided was invalid.', 'Wrangler', {timeOut: 0})
        },
        complete: function (jqXHR, status) {
            console.log(`Auth request completed with status: ${status} (${jqXHR.status})` );
        }
    })

}

// Prefilter to wrap around all calls
var prefilter = false
export function makeAjaxWrapper()
{
    if (prefilter) return
    prefilter = $.ajaxPrefilter(function (options) {

        var authToken = localStorage.getItem('authToken')

        // add authToken to all requests
        if (!options.beforeSend) {
            options.beforeSend = function (xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
            }
        }

        // parse response status code for errors/etc. (checkResponseCode is an example func)
        var completeCallbacks = []
        completeCallbacks.push([options.complete, function(evt) {
            if (evt.status === 403 || evt.status === 401) {
                console.log(evt)
                localStorage.clear()
                window.location = "/"
            }
        }])
        options.complete = completeCallbacks
    })
}

export function getArtifacts(path) {
    console.log(`getArtifacts(${path})`)
    return $.ajax({
        url: `${API_URL}/artifacts?path=${path}`,
        type: "GET"
    })
}

export function getPinnedArtifacts() {
    console.log(`getPinnedArtifacts()`)
    return $.ajax({
        url: `${API_URL}/artifacts/pinned`,
        type: "GET"
    })
}

export function getDownload(artifact) {
    artifact = encodeURIComponent(artifact.replace(/^\/+/, ''))
    console.log(`getDownload(${artifact})`)
    return $.ajax({
        url: `${API_URL}/artifacts/download?artifact=${artifact}`,
        type: "GET"
    })
}

export function getMetadata(artifact) {
    artifact = encodeURIComponent(artifact.replace(/^\/+/, ''))
    console.log(`getMetadata(${artifact})`)
    console.log(`${API_URL}/artifacts/metadata?artifact=${artifact}`)
    return $.ajax({
        url: `${API_URL}/artifacts/metadata?artifact=${artifact}`,
        type: "GET"
    })
}

export function getManifest(artifact) {
    console.log(`getManifest(${artifact})`)
    return $.ajax({
        url: `${API_URL}/artifacts/manifest?artifact=${artifact}`,
        type: "GET"
    })
}