import $ from 'jquery'
import * as buildbot from './buildbot.api'
import url from 'url'

// sweetalert2
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

$(document).on('click', '.actual-file a', evt => {
    evt.preventDefault()
    var link = url.parse($(evt.currentTarget).attr('href')).pathname

    // fix for root items, for some reason url.parse results in // prefix
    if (link.substr(0, 2) === '//') {
        let linkSegments = link.split('/')
        link = linkSegments[linkSegments.length - 1]
    }

    buildbot.getDownload(link)
        .then(result => {
            window.location = result.downloadUrl
        })
        .catch(err => {

            console.log(err)

            Swal.fire({
                title: 'Error!',
                text: err.statusText,
                type: 'error'
            })
        })
})

$(document).on('click', '#login-form #login', evt => {
    evt.preventDefault()

    buildbot.getAuthToken($('#user').val(), $('#pass').val())
        .then(json => {
            console.log(json)
            window.location.reload()
        })
        .catch(err => {
            console.error(err)
            localStorage.removeItem('authToken')
            window.location.reload()
        })
})

$(document).on('click', '#logout', evt => {
    evt.preventDefault()
    localStorage.removeItem('authToken')
    window.location.reload()
})

$(document).on('click', '.js-install-ipa', evt => {
    window.location = $(evt.currentTarget).data('link')
})